import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import { Box } from "@mui/system";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Grid } from "@mui/material";
import styles from "./InfoFooter.styles";
import { useTheme } from "@mui/material/styles";
import { useMediaQuery } from "@mui/material";

const InfoFooter = (props) => {
  const {
    students,
    credits,
    weeks,
    courseOfferingType,
    unitType,
    accommodations,
  } = props;
  const classes = styles();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("sm"));

  // const [toggleAccommodations, setToggleAccommodations] = useState(false);

  // useEffect(() => {
  //   if (props.demographics.issuerId) {
  //     const toggleUserArray = process.env.REACT_APP_USERNAME_LIST.split(
  //       ","
  //     ).map((item) => item.trim());
  //     const displayAccommodations =
  //       String(process.env.REACT_APP_TOGGLE_FEATURE).toLowerCase() ===
  //       "true";
  //     setToggleAccommodations(
  //       displayAccommodations
  //         ? true
  //         : toggleUserArray.includes(props.demographics.username.toLowerCase())
  //     );
  //   }
  // }, [props.demographics.issuerId]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Box className={matches ? classes.footerStyle : classes.footerMobileStyle}>
      <Grid
        container
        spacing={1}
        gap={matches ? 0 : 3}
        className={classes.mainContent}
      >
        <Grid item sm={3} className={classes.leftBox}>
          <Typography
            component="p"
            sx={{ fontWeight: "700", lineHeight: "28px", fontSize: "24px" }}
          >
            {students}
            <Typography
              sx={{ lineHeight: "21px", fontSize: "15px" }}
              component="p"
            >
              {`Students`}
            </Typography>
          </Typography>
        </Grid>

        {/* {toggleAccommodations && ( */}
        <>
          <Divider
            orientation="vertical"
            variant="middle"
            flexItem
            className={matches ? classes.dividerColor : classes.hide}
          />
          <Grid item sm={3} className={classes.centerBox}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                width: "100%",
              }}
            >
              <Typography
                component="p"
                sx={{
                  fontWeight: "700",
                  lineHeight: "28px",
                  fontSize: "24px",
                }}
              >
                {props.accommodations ? accommodations : 0}
              </Typography>

              <Typography
                sx={{
                  lineHeight: "21px",
                  fontSize: "15px",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  maxWidth: "180px",
                  width: "100%",
                  textAlign: "center",
                }}
                component="p"
              >
                {`Accommodations`}
              </Typography>
            </Box>
          </Grid>
        </>
        {/* )} */}

        <Divider
          orientation="vertical"
          variant="middle"
          flexItem
          className={matches ? classes.dividerColor : classes.hide}
        />
        <Grid
          item
          sm={3}
          className={matches ? classes.centerBox : classes.hide}
        >
          <Typography
            component="p"
            sx={{ fontWeight: "700", lineHeight: "28px", fontSize: "24px" }}
          >
            {credits}
            <Typography
              sx={{ lineHeight: "21px", fontSize: "15px" }}
              component="p"
            >
              {unitType}
              {/* {courseOfferingType === "DA" ? `Units` : `Credits`} */}
            </Typography>
          </Typography>
        </Grid>
        <Divider
          orientation="vertical"
          variant="middle"
          flexItem
          className={matches ? classes.dividerColor : classes.hide}
        />
        <Grid item sm={2} className={matches ? classes.rightBox : classes.hide}>
          <Typography
            component="p"
            sx={{ fontWeight: "700", lineHeight: "28px", fontSize: "24px" }}
          >
            {weeks}
            <Typography
              sx={{ lineHeight: "21px", fontSize: "15px" }}
              component="p"
            >
              {`Weeks`}
            </Typography>
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
};

const mapStateToProps = (state) => {
  return {
    demographics: state.demographics,
  };
};

export default connect(mapStateToProps)(InfoFooter);
