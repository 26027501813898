import { makeStyles } from "@material-ui/core/styles";
import { useTheme } from "@mui/material/styles";
import { Typography, useMediaQuery } from "@mui/material";
import { Box } from "@material-ui/core";
import CardLink from "./CardLink";
import SupportIcon from "../images/Support.svg";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined"; 

const useStyles = makeStyles(() => ({
  // Desktop View
  mainContainer: {
    background: "#FFFFFF",
    borderRadius: "3px",
    boxShadow: "0px 2px 4px rgba(0, 0, 0,0.15)",
    padding: "1px",
    minWidth: "257px",
  },

  // MobileView
  mainContainerMobile: {
    minWidth: "327px",
    background: "#FFFFFF",
    borderRadius: "3px",
    boxShadow: "0px 2px 4px rgba(0, 0, 0,0.15)",
    padding: "1px",
  },

  // Universal for Desktop and Mobile View
  innerContainer: {
    margin: "25px",
  },
  infoContainer: {
    display: "flex",
    marginBottom: "22px",
  },
  linkContainer: {
    width: "100%",
    display: "flex",
    justifyContent: "flex-start",
  },
  supportIcon: {
    width: "25px",
    height: "25px",
    margin: "0px 5px 0px 0px",
  },
  emailIconRoot: {
    marginRight: "5px",
  },
}));

const AdminSupportCard = () => {
  const classes = useStyles();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("sm"));

  const technicalSupportNumber = `(800) 800-3493`;

  return (
    <>
      <Box
        className={
          matches ? classes.mainContainer : classes.mainContainerMobile
        }
      >
        <Box className={classes.innerContainer}>
          <Box sx={{ marginBottom: "16px" }}>
            <Typography color="secondary" variant="subtitle1" component="h2">
              {`Administrative Support`}
            </Typography>
          </Box>
          <Box>
            <Typography
              variant="subtitle2"
              component="h3"
              sx={{ marginBottom: "16px" }}
            >{`Technical Support`}</Typography>
          </Box>
          <Box className={classes.infoContainer}>
            <img
              data-testId="supportIcon"
              src={SupportIcon}
              alt=""
              className={classes.supportIcon}
            />
            <Typography>{technicalSupportNumber}</Typography>
          </Box>
          <Box>
            <Typography
              sx={{  padding: "2px 0px",
              fontSize: "14px",
              fontStyle: "italic",
              marginBottom: "24px",
              color: "#68757c", }}
             
            >{`For policy questions and other general support needs, submit an issue to the Faculty Help Desk.`}</Typography>
          </Box>
          <Box className={classes.linkContainer}>
            {/* <EmailOutlinedIcon
              color="primary"
              classes={{ root: classes.emailIconRoot }}
            /> */}
            <CardLink
              //externalLink={true}
              ariaId="faculty_help_desk_email"
              ariaRead="Open Faculty Help Desk Submission Link"
              altText="helpdeskLink"
              content="Faculty Help Desk"
              link={"/helpdesksubmission"}
            />
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default AdminSupportCard;
