import React, { useState, useCallback, useEffect } from "react";
import { Card, CardContent, Typography, Grid } from "@mui/material";
import { Box } from "@mui/system";
import { useLocation } from "react-router-dom";
import DescriptionBox from "../../../../components/DescriptionBox.jsx";
import ClassButton from "./ClassButton.jsx";
import InfoFooter from "./InfoFooter.jsx";
import CardLink from "../../../../components/CardLink";
import RegisterButton from "../../../facultyWorkshops/components/RegisterButton";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import styles from "./CourseCardDisplay.styles";
import { useTheme, ThemeProvider } from "@mui/material/styles";
import { useMediaQuery } from "@mui/material";
import Snackbar from "@material-ui/core/Snackbar";
import SnackbarContentWrapper from "../../../../components/SnackbarContentWrapper.jsx";
import { ViewCertificate } from "../../../facultyWorkshops/components/ViewCertificate.jsx";
import {
  romanTest,
  capitalizeFoundParameter,
} from "../../../../utils/library.js";
import { connect } from "react-redux";
import facultyPortalTheme from "../../../../facultyPortalTheme.js";
import AlertBox from "./AlertBox.jsx";

const CourseCard = (props) => {
  const { currentCourse, type } = props;
  const classes = styles();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("sm"));
  const matchesDesktop = useMediaQuery(theme.breakpoints.up("md"));
  // const [toggleAccommodations, setToggleAccommodations] = useState(false);

  const [registered, setRegistered] = useState(
    currentCourse.status && currentCourse.status.includes("Scheduled")
  );
  const [waitlisted, setWaitlisted] = useState(
    currentCourse.status && currentCourse.status.includes("Waitlisted")
  );
  const [withdrawn, setWithdrawn] = useState(
    currentCourse.status && currentCourse.status[0] === "D"
  );
  const [pathname, setPathname] = useState("");
  const [joinWaitlist, setJoinWaitlist] = useState(false);
  const [leftButtonLabel, setLeftButtonLabel] = useState("");
  const [rightButtonLabel, setRightButtonLabel] = useState("");
  const [openSuccess, setOpenSuccess] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarType, setSnackbarType] = useState("");
  const [actionNeeded, setActionNeeded] = useState(false);

  const toggleDatabaseDate = new Date(process.env.REACT_APP_TOGGLE_DATE);
  const todaysDate = new Date().toLocaleDateString("en-US", {
    timeZone: "America/Phoenix",
  });
  const courseEndDate = new Date(currentCourse.endDate).toLocaleDateString(
    "en-US",
    {
      timeZone: "America/Phoenix",
    }
  );
  const location = useLocation();
  const isDashboard = location.pathname === "/";

  useEffect(() => {
    setPathname(new window.URL(window.location.href).pathname);
  }, []);

  // useEffect(() => {
  //   if (props.demographics.issuerId) {
  //     const toggleUserArray = process.env.REACT_APP_USERNAME_LIST.split(
  //       ","
  //     ).map((item) => item.trim());
  //     const displayAccommodations =
  //       String(process.env.REACT_APP_TOGGLE_FEATURE).toLowerCase() ===
  //       "true";
  //     setToggleAccommodations(
  //       displayAccommodations
  //         ? true
  //         : toggleUserArray.includes(props.demographics.username.toLowerCase())
  //     );
  //   }
  // }, [props.demographics.issuerId]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (
      currentCourse &&
      currentCourse.courseType !== "WS" &&
      Array.isArray(props.studentAccommodations) 
    ) {
      setActionNeeded(
        checkCourseAccommodations(currentCourse, props.studentAccommodations)
      ); 
    }
  }, [props.studentAccommodations, currentCourse]);

  const checkCourseAccommodations = (
    currentCourseInput,
    studentAccommodationsInput
  ) => {
    let hasAccommodations = false;

    currentCourseInput.accommodatedStudentIrnList?.forEach((irn) => {
      let studentIrnFound = false;

      studentAccommodationsInput?.forEach((accommodation) => {
        if (`${irn}` === `${accommodation.studentIrn}`) {
          studentIrnFound = true;
          if (
            accommodation.facultyIrn === 0 &&
            accommodation.courseOfferingId === 0
          ) {
            hasAccommodations = true;
          }
          accommodation.facultyAcknowledgements?.forEach((accommodationType) => {
            const isNullDates =
              accommodationType.accommodationStartDate === null &&
              accommodationType.accommodationEndDate === null;

            const isWithinDateRange = () => {
              if (!isNullDates) {
                const startDate = new Date(accommodationType.accommodationStartDate);
                const endDate = new Date(accommodationType.accommodationEndDate);
                const courseStartDate = currentCourse.startDate
                  ? new Date(currentCourse.startDate)
                  : null;
                const courseEndDate = currentCourse.endDate
                  ? new Date(currentCourse.endDate)
                  : null;
                return (
                  (endDate &&
                    endDate >= courseStartDate &&
                    endDate <= courseEndDate) ||
                  (startDate &&
                    startDate >= courseStartDate &&
                    startDate <= courseEndDate) ||
                  (startDate &&
                    endDate &&
                    startDate <= courseStartDate &&
                    endDate >= courseEndDate)
                );
              }
              return true;
            };

            const accommodationCourseMatch =
              accommodationType.courseId === null
                ? true
                : accommodationType.courseId === currentCourse.courseId
                ? true
                : false;

            const isApproved =
              accommodationType.status?.toLowerCase() === "approved" ||
              accommodationType.status === null;

            if (isWithinDateRange() && accommodationCourseMatch && isApproved && !accommodationType.dateAcknowledged) {
              hasAccommodations = true;
            }
          });
        }
      })

      if (!studentIrnFound) {
        hasAccommodations = true;
      }
    });

    return hasAccommodations;
  };

  useEffect(() => {
    if (currentCourse.status && currentCourse.status !== undefined) {
      setRegistered(currentCourse.status.toLowerCase().includes("scheduled"));
      setWaitlisted(currentCourse.status.includes("Waitlisted"));
      setWithdrawn(currentCourse.status.includes("Dropped"));
    }
  }, [currentCourse.status]);

  useEffect(() => {
    setJoinWaitlist(
      !isPastDeadlineDate(currentCourse.startDate) && // don't show join waitlist button if past deadline
        currentCourse.noOfParticipants >= currentCourse.capacity &&
        !registered &&
        !waitlisted
    );
  }, [currentCourse.status, registered, waitlisted]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleCapacity = useCallback(() => {
    console.log(currentCourse.capacity);
    console.log(currentCourse.noOfParticipants);
  }, [currentCourse.courseId, currentCourse.startDate]); // eslint-disable-line react-hooks/exhaustive-deps

  //Below code is to get deadline date for registering a workshop --- needs to be revisited to fix timezone issues
  const isPastDeadlineDate = (courseStartDate) => {
    const todaysDate = new Date();
    const startDateObj = new Date(courseStartDate);
    let deadlineDateForStartDate = new Date(
      startDateObj.setTime(startDateObj.getTime() - 5 * 86400000)
    );
    return deadlineDateForStartDate < todaysDate;
  };

  //check if the course is past the end date
  const hasCourseEnded = (courseEndDate) => {
    //add 7 days plus Accommodate for timezone issues , date format issues status date 03/07/2024
    const todaysDate = new Date();
    const endDateObj = new Date(courseEndDate);
    // console.log (todaysDate + " " + endDateObj + " ");
    // console.log(todaysDate > endDateObj);
    return todaysDate > endDateObj;
  };

  const isCurrentCourse = (courseStartDate, courseEndDate) => {
    const todaysDate = new Date();
    const startDateObj = new Date(courseStartDate);
    const endDateObj = new Date(courseEndDate);
    return todaysDate >= startDateObj && todaysDate <= endDateObj;
  };

  const getLeftButtonLabel = useCallback(() => {
    switch (true) {
      case waitlisted:
        setLeftButtonLabel("You're waitlisted!");
        break;
      case registered:
        setLeftButtonLabel("You're registered!");
        break;
      case !isPastDeadlineDate(currentCourse.startDate) &&
        currentCourse.noOfParticipants >= currentCourse.capacity:
        setLeftButtonLabel("Join the waitlist");
        break;
      case !isPastDeadlineDate(currentCourse.startDate) &&
        currentCourse.noOfParticipants < currentCourse.capacity:
        setLeftButtonLabel("Register now");
        break;
      default:
        break;
    }
  }, [
    waitlisted,
    registered,
    currentCourse.startDate,
    currentCourse.noOfParticipants,
    currentCourse.capacity,
  ]);

  const getRightButtonLabel = useCallback(() => {
    switch (true) {
      case withdrawn:
        setRightButtonLabel("Withdrawn");
        break;
      case waitlisted:
        setRightButtonLabel("Withdraw from waitlist");
        break;
      case registered && !isPastDeadlineDate(currentCourse.startDate):
        setRightButtonLabel("Withdraw from workshop");
        break;
      default:
        break;
    }
  }, [withdrawn, waitlisted, registered, currentCourse.startDate]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    getLeftButtonLabel();
    getRightButtonLabel();
  }, [getLeftButtonLabel, getRightButtonLabel]);

  // Below code is to handle snackbars for successful or errored submissions
  // -------------------------------------------------------------------------------------------
  const renderSnackbar = () => (
    <>
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        open={openSuccess}
        autoHideDuration={5000}
        onClose={handleCloseSnackBar}
      >
        <SnackbarContentWrapper
          onClose={handleCloseSnackBar}
          handleRetryClick={handleRetryApiCall}
          variant={snackbarType}
          message={snackbarMessage}
        />
      </Snackbar>
    </>
  );
  const handleCloseSnackBar = (event, reason) => {
    setOpenSuccess(false);
  };

  const handleRetryApiCall = () => {
    switch (snackbarMessage) {
      case "Error fetching workshop capacity.":
        handleCapacity();
        break;
      default:
        break;
    }
  };
  // -------------------------------------------------------------------------------------------
  return (
    <ThemeProvider theme={facultyPortalTheme}>
      <Card
        className={matches ? classes.desktopCard : classes.mobileCard}
        sx={isDashboard ? {} : { maxWidth: "609px" }}
      >
        <CardContent
          className={
            matches ? classes.desktopCardContent : classes.mobileCardContent
          }
        >
          <Typography
            className={matches ? classes.desktopTitle : classes.mobileTitle}
            component="h3"
            id={`${currentCourse.courseId}: ${romanTest(
              currentCourse.courseTitle
            )}`}
          >
            {`${currentCourse.courseId}`}:{" "}
            {capitalizeFoundParameter(romanTest(currentCourse.courseTitle), [
              "ai",
            ])}
          </Typography>

          <div
            className={
              currentCourse.offeringType === "WS"
                ? matchesDesktop
                  ? classes.facultyWorkshopText
                  : classes.mobileFacultyWorkshoptext
                : classes.hide
            }
          >
            <Typography>Faculty Workshop</Typography>
          </div>
          <div
            className={
              matchesDesktop && isDashboard
                ? classes.dashboardDescriptionAndButtonContainer
                : classes.mobileDashboardDescriptionAndButtonContainer
            }
          >
            <Box
              className={
                matches
                  ? classes.descriptionBoxContainer
                  : classes.descriptionBoxContainerMobile
              }
            >
              <DescriptionBox
                onlyDate={pathname === "/facultyworkshops"}
                startDate={
                  currentCourse.startDate !== null &&
                  `${currentCourse.startDate.split(" ")[0]}`
                }
                endDate={
                  currentCourse.endDate !== null &&
                  `${currentCourse.endDate.split(" ")[0]}`
                }
                date=""
                campus={
                  currentCourse.offeringType === "WS"
                    ? "hideCampus"
                    : `${currentCourse.orgName}`
                }
                role={
                  currentCourse.schedulingRole === "STUDENT" ||
                  !currentCourse.schedulingRole
                    ? "Participant"
                    : currentCourse.schedulingRole
                }
                groupId={`${currentCourse.groupId}`}
                courseCard={true}
                offeringType={currentCourse.offeringType}
                wsUser={props.wsUser}
              ></DescriptionBox>

              <div
                className={
                  pathname === "/facultyworkshops"
                    ? currentCourse.offeringType === "WS"
                      ? new Date(currentCourse.startDate) <= new Date() &&
                        new Date(currentCourse.endDate) >= new Date()
                        ? classes.hide
                        : matches
                        ? classes.workshopDescription
                        : classes.workshopDescriptionMobile
                      : classes.hide
                    : classes.hide
                }
              >
                <Typography>{`${currentCourse.description}`}</Typography>
              </div>
            </Box>
            <br />
            <Box className={matches ? classes.show : classes.mobileButton}>
              {props.type === "courseCardDisplay" ||
              props.type === "extendedCourses" ||
              (props.type === "myWorkshops" &&
                isCurrentCourse(
                  currentCourse.startDate,
                  currentCourse.endDate
                ) &&
                registered) ? (
                <ClassButton
                  courseOfferingId={
                    (currentCourse.offeringType !== "WS" // osiris course
                      ? "OSIRIS:"
                      : props.wsUser || new Date() >= toggleDatabaseDate //  new FWS logic
                      ? new Date(currentCourse.startDate) >= toggleDatabaseDate
                        ? "FWS:"
                        : "SDW:"
                      : "") + // old SDW logic, course membership service brings "SDW:courseOfferingId"
                    currentCourse.courseOfferingId
                  }
                  courseId={currentCourse.courseId}
                  isCbedaCourse={currentCourse.cbeda}
                  courseName={`${currentCourse.courseId} : ${currentCourse.courseTitle}`}
                  accommodations={
                    // toggleAccommodations && 
                    currentCourse.offeringType !== "WS"
                      ? currentCourse.accommodatedStudentIrnList
                      : ""
                  }
                />
              ) : props.workshopPageCard &&
                (currentCourse.schedulingRole === "OBSERVER" ||
                  currentCourse.schedulingRole === "FACULTY") ? (
                <>
                  {hasCourseEnded(currentCourse.endDate) && (
                    <CardLink
                      externalLink={true}
                      ariaRead="Open View Workshop materials Link"
                      ariaId={`${currentCourse.courseId}: ${romanTest(
                        currentCourse.courseTitle
                      )}`}
                      altText="Link"
                      content="View Course"
                      link={
                        currentCourse.offeringType !== "WS" // osiris course
                          ? `${process.env.REACT_APP_NEW_VIEW_COURSE_MATERIALS}&courseOfferingId=OSIRIS:${currentCourse.courseOfferingId}&irn=${props.demographics.issuerId}`
                          : props.wsUser || new Date() >= toggleDatabaseDate //  new FWS logic
                          ? new Date(currentCourse.startDate) >=
                            toggleDatabaseDate
                            ? `${process.env.REACT_APP_NEW_VIEW_COURSE_MATERIALS}&courseOfferingId=FWS:${currentCourse.courseOfferingId}&irn=${props.demographics.issuerId}`
                            : `${process.env.REACT_APP_NEW_VIEW_COURSE_MATERIALS}&courseOfferingId=SDW:${currentCourse.courseOfferingId}&irn=${props.demographics.issuerId}`
                          : `${process.env.REACT_APP_NEW_VIEW_COURSE_MATERIALS}&courseOfferingId=SDW:${currentCourse.courseOfferingId}&irn=${props.demographics.issuerId}`
                      }
                    />
                  )}
                  {!hasCourseEnded(currentCourse.endDate) && (
                    <RegisterButton
                      pageType={props.type}
                      withdrawn={withdrawn}
                      setWithdrawn={setWithdrawn}
                      registered={registered}
                      setRegistered={setRegistered}
                      waitlisted={waitlisted}
                      setWaitlisted={setWaitlisted}
                      joinWaitlist={joinWaitlist}
                      startDate={currentCourse.startDate}
                      endDate={currentCourse.endDate}
                      courseCode={currentCourse.courseId}
                      courseTitle={currentCourse.courseTitle}
                      rosterId={currentCourse.rosterId}
                      capacity={currentCourse.capacity}
                      noOfParticipants={currentCourse.noOfParticipants}
                      courseOfferingId={currentCourse.courseOfferingId}
                      leftButtonLabel={
                        currentCourse.schedulingRole === "OBSERVER"
                          ? "You're Observing"
                          : "You're Facilitating"
                      }
                      rightButtonLabel={rightButtonLabel}
                      schedulingRole={currentCourse.schedulingRole}
                      isPastDeadlineDate={isPastDeadlineDate()}
                      faculty={true}
                    />
                  )}
                </>
              ) : props.workshopPageCard &&
                currentCourse.schedulingRole === "STUDENT" &&
                currentCourse.status[0] === "C" ? (
                <>
                  <CardLink
                    externalLink={true}
                    ariaRead="Open View Workshop materials Link"
                    ariaId={`${currentCourse.courseId}: ${romanTest(
                      currentCourse.courseTitle
                    )}`}
                    altText="Link"
                    content="View Course"
                    link={
                      currentCourse.offeringType !== "WS" // osiris course
                        ? `${process.env.REACT_APP_NEW_VIEW_COURSE_MATERIALS}&courseOfferingId=OSIRIS:${currentCourse.courseOfferingId}&irn=${props.demographics.issuerId}`
                        : props.wsUser || new Date() >= toggleDatabaseDate //  new FWS logic
                        ? new Date(currentCourse.startDate) >=
                          toggleDatabaseDate
                          ? `${process.env.REACT_APP_NEW_VIEW_COURSE_MATERIALS}&courseOfferingId=FWS:${currentCourse.courseOfferingId}&irn=${props.demographics.issuerId}`
                          : `${process.env.REACT_APP_NEW_VIEW_COURSE_MATERIALS}&courseOfferingId=SDW:${currentCourse.courseOfferingId}&irn=${props.demographics.issuerId}`
                        : `${process.env.REACT_APP_NEW_VIEW_COURSE_MATERIALS}&courseOfferingId=SDW:${currentCourse.courseOfferingId}&irn=${props.demographics.issuerId}`
                    }
                  />
                  <Grid
                    container
                    sx={{
                      display: "flex",
                      backgroundColor: "#007558",
                      justifyContent: "space-between",
                      alignItems: "center",
                      padding: "10px",
                      minHeight: "65px",
                      marginTop: "10px",
                    }}
                  >
                    <Grid
                      item
                      sx={{
                        display: "flex",
                        justifyContent: "flex-start",
                        alignItems: "flex-start",
                        columnGap: "5px",
                        margin: "0px 0px 0px 10px",
                      }}
                    >
                      <TaskAltIcon
                        sx={{
                          color: "white",
                        }}
                      />
                      <Typography
                        sx={{
                          color: "white",
                          fontSize: "12px",
                          fontWeight: "bold",
                        }}
                      >
                        Workshop completed. Congratulations!
                      </Typography>
                    </Grid>

                    <Grid
                      item
                      sx={{
                        display: "flex",
                        justifyContent: "flex-end",
                        alignItems: "flex-end",
                      }}
                    >
                      <ViewCertificate
                        workshopName={currentCourse.courseTitle}
                        facultyName={currentCourse.facultyName}
                        startDate={currentCourse.startDate}
                        endDate={currentCourse.endDate}
                      />
                    </Grid>
                  </Grid>
                </>
              ) : props.workshopPageCard &&
                currentCourse.status[0] !== "C" &&
                currentCourse.schedulingRole !== "OBSERVER" &&
                currentCourse.schedulingRole !== "FACULTY" ? (
                <>
                  {hasCourseEnded(currentCourse.endDate) && (
                    <CardLink
                      externalLink={true}
                      ariaRead="Open View Workshop materials Link"
                      ariaId={`${currentCourse.courseId}: ${romanTest(
                        currentCourse.courseTitle
                      )}`}
                      altText="Link"
                      content="View Course"
                      link={
                        currentCourse.offeringType !== "WS" // osiris course
                          ? `${process.env.REACT_APP_NEW_VIEW_COURSE_MATERIALS}&courseOfferingId=OSIRIS:${currentCourse.courseOfferingId}&irn=${props.demographics.issuerId}`
                          : props.wsUser || new Date() >= toggleDatabaseDate //  new FWS logic
                          ? new Date(currentCourse.startDate) >=
                            toggleDatabaseDate
                            ? `${process.env.REACT_APP_NEW_VIEW_COURSE_MATERIALS}&courseOfferingId=FWS:${currentCourse.courseOfferingId}&irn=${props.demographics.issuerId}`
                            : `${process.env.REACT_APP_NEW_VIEW_COURSE_MATERIALS}&courseOfferingId=SDW:${currentCourse.courseOfferingId}&irn=${props.demographics.issuerId}`
                          : `${process.env.REACT_APP_NEW_VIEW_COURSE_MATERIALS}&courseOfferingId=SDW:${currentCourse.courseOfferingId}&irn=${props.demographics.issuerId}`
                      }
                    />
                  )}
                  {!hasCourseEnded(currentCourse.endDate) && (
                    <RegisterButton
                      pageType={props.type}
                      withdrawn={withdrawn}
                      setWithdrawn={setWithdrawn}
                      registered={registered}
                      setRegistered={setRegistered}
                      waitlisted={waitlisted}
                      setWaitlisted={setWaitlisted}
                      joinWaitlist={joinWaitlist}
                      startDate={currentCourse.startDate}
                      endDate={currentCourse.endDate}
                      courseCode={currentCourse.courseId}
                      courseTitle={currentCourse.courseTitle}
                      rosterId={currentCourse.rosterId}
                      capacity={currentCourse.capacity}
                      noOfParticipants={currentCourse.noOfParticipants}
                      courseOfferingId={currentCourse.courseOfferingId}
                      leftButtonLabel={leftButtonLabel}
                      rightButtonLabel={rightButtonLabel}
                      schedulingRole={currentCourse.schedulingRole}
                    />
                  )}
                </>
              ) : (
                <ClassButton
                  courseOfferingId={
                    (currentCourse.offeringType !== "WS" // osiris course
                      ? "OSIRIS:"
                      : props.wsUser || new Date() >= toggleDatabaseDate //  new FWS logic
                      ? new Date(currentCourse.startDate) >= toggleDatabaseDate
                        ? "FWS:"
                        : "SDW:"
                      : "") + // old SDW logic, course membership service brings "SDW:courseOfferingId"
                    currentCourse.courseOfferingId
                  }
                  courseId={currentCourse.courseId}
                  isCbedaCourse={currentCourse.cbeda}
                  courseName={`${currentCourse.courseId} : ${currentCourse.courseTitle}`}
                />
              )}
            </Box>
          </div>
          {isDashboard && actionNeeded && 
          // toggleAccommodations &&
           <AlertBox />}
        </CardContent>
        <div
          className={currentCourse.offeringType === "WS" ? classes.hide : ""}
        >
          <Box className={matches ? classes.show : classes.infoBoxMobile}>
            <InfoFooter
              students={
                currentCourse.actualStudentCount == null
                  ? ""
                  : currentCourse.actualStudentCount
              }
              accommodations={
                currentCourse.accommodatedStudentIrnList == null
                  ? ""
                  : currentCourse.accommodatedStudentIrnList.length
              }
              credits={
                currentCourse.courseCredits == null
                  ? ""
                  : currentCourse.courseCredits
              }
              weeks={
                currentCourse.courseWeeks == null
                  ? ""
                  : currentCourse.courseWeeks
              }
              unitType={
                currentCourse.unitType == null ? "" : currentCourse.unitType
              }
              courseOfferingType={currentCourse.offeringType}
            ></InfoFooter>
          </Box>
        </div>
        {openSuccess && renderSnackbar()}
      </Card>
    </ThemeProvider>
  );
};

const mapStateToProps = (state) => {
  return {
    demographics: state.demographics,
    studentAccommodations: state.studentAccommodations,
  };
};

export default connect(mapStateToProps)(CourseCard);
