import React, { useState, useEffect, useRef } from "react";
import {
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Box,
  useMediaQuery,
} from "@material-ui/core";
import ClassButton from "../../dashboard/components/currentCourses/ClassButton";
import CalendarIcon from "../../../images/CalendarIcon.svg";
import styles from "../styles";
import CourseTableRow from "./CourseTableRow";
import { IconButton, MenuItem, Select, InputBase, styled } from "@mui/material";
import { ChevronLeft, ChevronRight } from "@mui/icons-material";
import { useTheme, createTheme, ThemeProvider } from "@mui/material/styles";

function CourseTableCard(props) {
  const useStyles = styles;
  const classes = useStyles();
  const pageTheme = useTheme();

  const matches = useMediaQuery(pageTheme.breakpoints.up("md"));
  const tabletMatches = useMediaQuery(pageTheme.breakpoints.up("sm"));

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const toggleDatabaseDate = new Date(process.env.REACT_APP_TOGGLE_DATE);

  const start = page * rowsPerPage + 1;
  const end = Math.min(
    (page + 1) * rowsPerPage,
    props.course.studentList.length
  );
  const totalRows = props.course.studentList.length;

  const previousButtonRef = useRef(null);
  const nextButtonRef = useRef(null);

  const gridRef = useRef(null);

  const handlePageChange = (event, newPage) => {
    if (event) {
      event.preventDefault();
    }

    handleChangePage(event, newPage);

    // Handle focus shift when a button becomes disabled
    if (newPage === 0 && previousButtonRef.current) {
      nextButtonRef.current.focus({ preventScroll: true });
    } else if (
      newPage >= Math.ceil(totalRows / rowsPerPage) - 1 &&
      nextButtonRef.current
    ) {
      previousButtonRef.current.focus({ preventScroll: true });
    }

    // Scroll to the specific Grid that triggered the pagination
    if (gridRef.current) {
      gridRef.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  };

  const selectRef = useRef(null); // Reference to the Select component

  const handleSelectChange = (event) => {
    // Handle change in selection
    handleChangeRowsPerPage(event);

    // Manually set focus back to the Select after an option is selected
    if (selectRef.current) {
      setTimeout(() => {
        selectRef.current.focus(); // Timeout helps in ensuring the dropdown closes before setting focus
      }, 0);
    }
  };

  // Custom MenuItem with focus styling
  const CustomMenuItem = styled(MenuItem)(({ theme }) => ({
    "&.Mui-focusVisible": {
      border: "2px solid black !important",
    },
  }));

  // Custom InputBase for Select
  const CustomInputBase = styled(InputBase)(({ theme }) => ({
    "& .MuiSelect-select": {
      border: "2px solid transparent",
      padding: "8px 32px 8px 8px",
      borderRadius: "4px",
      fontSize: "15px",
      display: "flex",
      alignItems: "center",
      "&:focus": {
        border: "2px solid black",
      },
    },
    "& .MuiSelect-icon": {
      right: "8px",
    },
  }));

  const theme = createTheme();

  // Reset pagination to page 1 when a new section is selected
  useEffect(() => {
    setPage(0);
  }, [props.checkedWorkshops]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const getMonthString = (month) => {
    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    return months[month - 1];
  };

  const updateDateFormat = (date) => {
    if (props.course.offeringType !== "WS" || !props.wsUser) {
      const [month, day, year] = date.split("/");
      return `${getMonthString(month)} ${day}, ${year}`;
    } else {
      const [year, month, day] = date.split("-");
      return `${getMonthString(month)} ${day}, ${year}`;
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <Grid
        container
        ref={gridRef}
        className={classes.tableHeaderContainer}
        component={Paper}
        elevation={2}
        xs={matches ? 11 : 12}
      >
        <Grid className={classes.tableHeaderText}>
          <Box>
            <Typography className={classes.courseText} component="h2">
              {`${props.course.courseId}: ${props.course.courseTitle}`}
            </Typography>
            <Typography>{`Group ID: ${props.course.groupId}`}</Typography>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                margin: "4px 0px 0px 0px",
              }}
            >
              <img
                src={CalendarIcon}
                alt="Calendar icon showing dates"
                aria-labelledby="calendarIconTitle"
                width="24"
                height="24"
              />
              <Typography
                data-testid="courseDuration"
                variant="body2"
                className={classes.dateText}
              >
                {`${updateDateFormat(
                  props.course.startDate
                )} - ${updateDateFormat(props.course.endDate)}`}
              </Typography>
            </Box>
          </Box>
        </Grid>

        <Box
          className={
            matches
              ? classes.classButtonContainer
              : classes.classButtonContainerMobile
          }
        >
          <ClassButton
            accommodationsPage={true}
            courseName={`${props.course.courseId}: ${props.course.courseTitle}`}
            courseOfferingId={
              (props.course.offeringType !== "WS"
                ? "OSIRIS:"
                : new Date(props.course.startDate) >= toggleDatabaseDate
                  ? "FWS:"
                  : "SDW:"
              ) + props.course.courseOfferingId         
            }
          />
        </Box>
        <Grid item xs={12} className={classes.tablePadding}>
          <TableContainer className={classes.tableContainer}>
            <Table>
              <TableHead className={classes.courseCardTableHeader}>
                <TableRow className={classes.tableRow}>
                  <TableCell
                    align="left"
                    className={classes.stickyColumnHeader}
                  >
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <span style={{ color: "#ffffff" }}>Student name</span>
                      <span style={{ color: "#ffffff" }}>IRN</span>
                    </div>
                  </TableCell>
                  <TableCell align="left" className={classes.tableText}>
                    Type
                  </TableCell>
                  <TableCell align="left" className={classes.tableText}>
                    Accommodations
                  </TableCell>
                  <TableCell align="left" className={classes.tableText}>
                    Notes
                  </TableCell>
                  <TableCell align="left" className={classes.tableText}>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <Typography>Final grade</Typography>
                      <Typography>issued</Typography>
                    </div>
                  </TableCell>
                  <TableCell align="center" className={classes.tableText}>
                    Action(s)
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {props.course.studentList
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((student) => (
                    <CourseTableRow
                      key={student.studentIrn}
                      student={student}
                      courseId={props.course.courseId}
                      courseStartDate={props.course.startDate}
                      courseEndDate={props.course.endDate}
                      courseOfferingId={props.course.courseOfferingId}
                      setAlreadyAcknowledged={props.setAlreadyAcknowledged}
                      setOpenModal={props.setOpenModal}
                      setActionNeededObject={props.setActionNeededObject}
                      actionNeededObject={props.actionNeededObject}
                      update={props.update}
                    />
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              width: "100%",
              alignItems: "center",
              gap: "1%",
              padding: "5px",
            }}
          >
            {/* Rows per Page Dropdown */}
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <Typography
                variant="body2"
                id="rows-per-page-label"
                sx={{ marginRight: 1 }}
              >
                Rows per page:
              </Typography>

              {/* Hidden element for screen readers to read the current value */}
              <span id="select-value-description" style={{ display: "none" }}>
                {rowsPerPage} rows per page
              </span>

              <Select
                value={rowsPerPage}
                onChange={(event) => {
                  handleSelectChange(event);
                }}
                input={<CustomInputBase inputRef={selectRef} />}
                inputProps={{
                  "aria-labelledby": "rows-per-page-label",
                  "aria-describedby": "select-value-description",
                }}
                onKeyDown={(event) => {
                  if (
                    (event.key === "Enter" ||
                      event.key === "ArrowDown" ||
                      event.key === "ArrowUp") &&
                    selectRef.current
                  ) {
                    setTimeout(() => {
                      selectRef.current.focus();
                    }, 0);
                  }
                }}
                MenuProps={{
                  PaperProps: {
                    sx: {
                      "& .MuiMenuItem-root": {
                        "&.Mui-focusVisible": {
                          border: "2px solid black !important",
                        },
                      },
                    },
                  },
                  onClose: () => {
                    if (selectRef.current) {
                      setTimeout(() => {
                        selectRef.current.focus();
                      }, 100);
                    }
                  },
                }}
              >
                <CustomMenuItem value={5}>5</CustomMenuItem>
                <CustomMenuItem value={10}>10</CustomMenuItem>
                <CustomMenuItem value={20}>20</CustomMenuItem>
              </Select>
            </Box>

            <div>
              {/* Screen reader aria-live region */}
              <div
                aria-live="polite"
                style={{ position: "absolute", left: "-9999px" }}
              >
                {`Displaying ${start}-${end} of ${totalRows}`}
              </div>

              {/* Container for Range and Pagination Controls */}
              <Box sx={{ display: "flex", alignItems: "center" }}>
                {/* Display Rows Range */}
                <Typography variant="body2" className={classes.rowDisplay}>
                  {`${start}-${end} of ${totalRows}`}
                </Typography>

                {/* Pagination Controls */}
                <IconButton
                  ref={previousButtonRef}
                  onClick={(event) => handlePageChange(event, page - 1)}
                  disabled={page === 0}
                  aria-label="previous"
                >
                  <ChevronLeft />
                </IconButton>
                <IconButton
                  ref={nextButtonRef}
                  onClick={(event) => handlePageChange(event, page + 1)}
                  disabled={page >= Math.ceil(totalRows / rowsPerPage) - 1}
                  aria-label="next"
                >
                  <ChevronRight />
                </IconButton>
              </Box>
            </div>
          </Box>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
}

export default CourseTableCard;
