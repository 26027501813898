import React from "react";
import useStyles from "./AlertBox.styles";
import severitywarning from "../../../../images/severitywarning.png"
import { Typography } from "@material-ui/core";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

const AlertBox = (props) => {
  
  const classes = useStyles();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("sm"));


  return (
    <div className= {classes.alertBox}>
        <img
          className={classes.alertIcon}
          src= {severitywarning}
          alt=""
      />
      <div className={classes.headerContainer }>
    
      <Typography
        className={classes.alertHeader}
        id={"alertBox"}
        component="h1"
      >
      
      {<b>{`Action needed`}</b>}{<br/>}
      </Typography>
      
        <Typography
        className={classes.alertText}
        component="h3"
      >
        
        {"You have one or more updates to accommodated students in this course. Visit Student Accommodations to learn more."}
        </Typography>
        </div>
    </div>
  );
};


export default (AlertBox);
