import Button from "@mui/material/Button";
import { useLocation } from "react-router-dom";
import LaunchIcon from "@mui/icons-material/Launch";
import { useTheme } from "@mui/material/styles";
import { useMediaQuery } from "@mui/material";
import { Box } from "@mui/system";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import useStyles from "./ClassButton.styles";
import CardLink from "../../../../components/CardLink";

const ClassButton = (props) => {
  const theme = useTheme();
  const classes = useStyles();
  const matches = useMediaQuery(theme.breakpoints.up("md"));
  const tabletMatches = useMediaQuery(theme.breakpoints.up("sm"));
  const location = useLocation();
  // Check if the current path is the root (dashboard page)
  const isDashboard = location.pathname === "/";

  return (
    <>
      <div
        className={
          matches ? classes.buttonContainer : (tabletMatches && props.accommodationsPage) ? classes.accomPageTablet : classes.buttonContainerMobile
        }
      >
        <Button
          sx={{
            borderRadius: "30px",
            marginBottom: "10px",
            //padding: "16px 48px",
            padding: `${matches ? "8px 32px" : "2px 16px"}`,
            marginRight: `${isDashboard ? "" : "16px"}`,
            width: `${props.accommodationsPage ? (matches ? "253px" : tabletMatches ? "253px" : "100%") :  (matches ? "253px" : "100%")}`,
            height: "50px",
            backgroundColor: "#DC3727",
            color: "white",
            textTransform: "none",
            "&:focus": {
              outline: "auto",
              outlineColor: "#000000",
            },
            "&:hover": {
              backgroundColor: `#DC3727`,
              outline: "auto",
              outlineColor: "#000000",
            },
          }}
          aria-label={`Go to class ${props.courseName}. Opens in new window`}
          variant="contained"
          startIcon={<LaunchIcon />}
          href={
            props.isCbedaCourse
              ? `${process.env.REACT_APP_D2LCLASS_URL}`
              : // : `${process.env.REACT_APP_GOTOCLASS_COURSE_MATERIALS}${props.courseOfferingId}`
                `${process.env.REACT_APP_NEW_VIEW_COURSE_MATERIALS}&courseOfferingId=${props.courseOfferingId}&irn=${props.demographics.issuerId}`
          }
          target="_blank"
        >
          {`Go to class`}
        </Button>
        <Button
          sx={isDashboard && props.accommodations && props.accommodations.length > 0 ? {
            marginBottom: "10px",
            // width: "253px",
            width: "100%",
            height: "51px",
            display: "flex",
            whiteSpace: "nowrap",
            justifyContent: "center",
            alignItems: "center",
            padding: "8px 32px",
            background: "#FFFFFF",
            border: "2px solid #DC3727",
            boxSizing: "border-box",
            borderRadius: "40px",
            fontStyle: "normal",
            fontWeight: "bold",
            fontSize: "16px",
            lineHeight: "24px",
            textTransform: "none",
            "&:focus": {
              outline: "auto",
              outlineColor: "#000000",
            },
          } : {
            display: "none"
          }
        }
          aria-label="accommodations"
          href="/manageAccommodations"
          target="_self"
        >
          {`Accommodations`}
        </Button>

        {props.feocsInviteLink &&
          props.feocsInviteLink.length > 0 &&
          props.feocsInviteLink.map(
            (course) =>
              props.courseOfferingId === "OSIRIS:" + course.courOffNum && (
                <div className={isDashboard ? "" : classes.hide}>
                  <Box
                    className={
                      matches ? classes.desktopLink : classes.mobileLink
                    }
                  >
                    <CardLink
                      survey={true}
                      content={"Course survey"}
                      link={course.invitationLink}
                    />
                  </Box>
                </div>
              )
          )}
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    feocsInviteLink: state.feocsInviteLink,
    demographics: state.demographics,
  };
};

export default connect(mapStateToProps)(ClassButton);
