import { useState, useEffect, useRef } from "react";
import useStyles from "./UpcomingWorkshops.styles";
import { useMediaQuery, Typography, Grid, TextField, MenuItem, Autocomplete, FormHelperText } from "@mui/material";
import { Box } from "@mui/system";
import { useTheme } from "@mui/material/styles";
import { connect } from "react-redux";
import CourseCard from "../../dashboard/components/currentCourses/CourseCard";
import NoWorkshopsCard from "./NoWorkshopsCard";
import { fixTimezoneDifference } from "../../../utils/library";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

const UpcomingWorkshops = (props) => {
  const { setValue } = props;
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("sm"));
  const classes = useStyles();

  const [noWorkshops, setNoWorkshops] = useState(true);
  const [originalWorkshops, setOriginalWorkshops] = useState([]);
  const [workshopsToDisplay, setWorkshopsToDisplay] = useState([]);
  const [userUsesAutocompleteSearch, setUserUsesAutocompleteSearch] = useState(false);
  const [userUsesMonthFilter, setUserUsesMonthFilter] = useState(false);


  const handleFutureSectionsForIrn = (sectionsList) => {
    return sectionsList.filter(
      (section) => new Date(section.startDate) > new Date()
    );
  };

  const generateWorkshopsToDisplay = (
    futureSectionsForIrnList,
    generalWorkshopsList
  ) => {
    const newGeneralWorkshopsList = generalWorkshopsList.filter((workshop) => {
      return workshop.capacity !== 0;
    });
    const tempList = newGeneralWorkshopsList.map((workshop) => {
      for (let i = 0; i < futureSectionsForIrnList.length; i++) {
        if (
          workshop.courseCode === futureSectionsForIrnList[i].courseCode &&
          workshop.startDate.split(" ")[0] === futureSectionsForIrnList[i].startDate
        ) {
          //myWorkshopFound
          return {
            ...workshop,
            status: futureSectionsForIrnList[i].status,
            role: futureSectionsForIrnList[i].role,
            rosterId: futureSectionsForIrnList[i].rosterId,
            id: futureSectionsForIrnList[i].id,
          };
        }
      }
      return { ...workshop, status: "", role: "", rosterId: null, id: null };
    });
    return tempList;
  };

  const generateWorkshopsWhenNoHistory = (generalWorkshopsList) => {
    const tempList = generalWorkshopsList.map((workshop) => {
      return { ...workshop, status: "", role: "", rosterId: null, id: null };
    });
    return tempList;
  };

  useEffect(() => {
    if (
      props.sectionsForIrn !== undefined &&
      props.sectionsForIrn.length === 0 &&
      props.workshops !== undefined &&
      props.workshops.length > 0
    ) {
      setNoWorkshops(false);
      setWorkshopsToDisplay(generateWorkshopsWhenNoHistory(props.workshops));
      setOriginalWorkshops(generateWorkshopsWhenNoHistory(props.workshops));
    } else {
      if (
        props.workshops !== undefined &&
        props.sectionsForIrn !== undefined &&
        props.workshops.length > 0 &&
        props.sectionsForIrn.length > 0
      ) {
        setNoWorkshops(false);

        const futureSectionsForIrnList = handleFutureSectionsForIrn(
          props.sectionsForIrn
        );
        const generalWorkshopsList = props.workshops;
        setWorkshopsToDisplay(
          generateWorkshopsToDisplay(
            futureSectionsForIrnList,
            generalWorkshopsList
          )
        );
        setOriginalWorkshops(
          generateWorkshopsToDisplay(
            futureSectionsForIrnList,
            generalWorkshopsList
          )
        );
      }
    }
  }, [props.workshops, props.sectionsForIrn]); // eslint-disable-line react-hooks/exhaustive-deps

  // useEffect(() => {
  //   if (
  //     props.sectionsForIrn !== undefined &&
  //     props.sectionsForIrn.length === 0
  //   ) {
  //     setWorkshopsToDisplay(props.workshops);
  //   }
  // }, [props.workshops, props.sectionsForIrn]); // eslint-disable-line react-hooks/exhaustive-deps


  //------------------------------------------------------------------------------------------------------------------------------------------
  // Code for Month Select Filter

  const [openMonthSelectList, setOpenMonthSelectList] = useState(false);
  const [filterMonthSelected, setFilterMonthSelected] = useState('');
  const [highlightedMonthIndex, setHighlightedMonthIndex] = useState(-1);

  const monthListRef = useRef(null);

  const generateMonthlyFilterOptions = () => {
    const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    const monthlyFilterOptions = new Set();
    originalWorkshops.forEach(workshop => {
      const startDate = fixTimezoneDifference(workshop.startDate);
      const option = `${months[startDate.getMonth()]} ${startDate.getFullYear()}`;
      if(!monthlyFilterOptions.has(option)) {
        monthlyFilterOptions.add(option);
      };
    })
    return ["All", ...monthlyFilterOptions];
  };

  useEffect(() => {
    if (highlightedMonthIndex >= 0 && monthListRef.current) {
      const optionElement = monthListRef.current.children[highlightedMonthIndex];
      if (optionElement) {
        optionElement.scrollIntoView({ block: 'nearest' });
      }
    }
  }, [highlightedMonthIndex]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (userUsesMonthFilter) {
      if (filterMonthSelected === "All") {
        setWorkshopsToDisplay(originalWorkshops);
      };
      if (filterMonthSelected !== "All" && filterMonthSelected !== "") {
        const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
        const monthSelected = filterMonthSelected.split(" ")[0];
        const yearSelected = filterMonthSelected.split(" ")[1];
        const workshops = originalWorkshops.filter(workshop => {
          const startDate = fixTimezoneDifference(workshop.startDate);
          return (
            months[startDate.getMonth()] === monthSelected &&
            startDate.getFullYear().toString() === yearSelected
          )
        });
        setWorkshopsToDisplay(workshops);
      };
    };
  }, [filterMonthSelected]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleFilterMonthMouseChange = (option) => {
    setCourseTitleInputValue("");
    setCourseTitleSearchSelected("");
    setHighlightedCourseTitleIndex(-1);
    setUserUsesAutocompleteSearch(false);

    if(!userUsesMonthFilter) {
      setUserUsesMonthFilter(true);
    };

    setFilterMonthSelected(option);
    setOpenMonthSelectList(false);
    setHighlightedMonthIndex(generateMonthlyFilterOptions().indexOf(option));
  };

  const handleFilterMonthKeyDownChange = (e) => {
    setCourseTitleInputValue("");
    setCourseTitleSearchSelected("");
    setHighlightedCourseTitleIndex(-1);
    setUserUsesAutocompleteSearch(false);

    if (!userUsesMonthFilter) {
      setUserUsesMonthFilter(true);
    }
  
    if (!openMonthSelectList && ['ArrowDown', 'ArrowUp', 'Enter', ' '].includes(e.key)) {
      setOpenMonthSelectList(!openMonthSelectList);
      return; // Ensure the list is opened before handling navigation
    }

    const monthListOptions = generateMonthlyFilterOptions();
  
    if (e.key === 'ArrowDown') {
      e.preventDefault();
      setHighlightedMonthIndex((prevIndex) => {
        const nextIndex = (prevIndex + 1) % monthListOptions.length;
        return nextIndex;
      });
    } else if (e.key === 'ArrowUp') {
      e.preventDefault();
      setHighlightedMonthIndex((prevIndex) => {
        const nextIndex = (prevIndex - 1 + monthListOptions.length) % monthListOptions.length;
        return nextIndex;
      });
    } else if (['Enter', ' '].includes(e.key) && highlightedMonthIndex >= 0) {
      e.preventDefault();
      handleFilterMonthMouseChange(monthListOptions[highlightedMonthIndex]);
    }
  
  };

  //------------------------------------------------------------------------------------------------------------------------------------------
  // Code for Course Title Filter

  const [openCourseTitleList, setOpenCourseTitleList] = useState(false);
  const [courseTitleListFiltered, setCourseTitleListFiltered] = useState([]);
  const [courseTitleSearchSelected, setCourseTitleSearchSelected] = useState('');
  const [highlightedCourseTitleIndex, setHighlightedCourseTitleIndex] = useState(-1);
  const [courseTitleInputValue, setCourseTitleInputValue] = useState('');

  const courseTitleListRef = useRef(null);

  const generateCourseTitleList = (workshopList) => {
    if (!workshopList) return [];

    const tempList = workshopList.map(workshop => { return workshop.courseTitle });
    const courseTitleList = new Set(["All Workshops", ...tempList]);
    return [...courseTitleList];
  };

  useEffect(() => {
    if(originalWorkshops) {
      const tempList = originalWorkshops.map(workshop => { return workshop.courseTitle });
      const courseTitleList = new Set(["All Workshops", ...tempList]);
      setCourseTitleListFiltered([...courseTitleList]);
    };
  }, [originalWorkshops]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (highlightedCourseTitleIndex >= 0 && monthListRef.current) {
      const optionElement = monthListRef.current.children[highlightedCourseTitleIndex];
      if (optionElement) {
        optionElement.scrollIntoView({ block: 'nearest' });
      }
    }
  }, [highlightedCourseTitleIndex]);

  useEffect(() => {
    if (userUsesAutocompleteSearch) {
      if (courseTitleSearchSelected === "All Workshops") {
        setWorkshopsToDisplay(originalWorkshops);
      } else {
        if (courseTitleSearchSelected !== "") {
          const workshops = originalWorkshops.filter(workshop => {
            return (
              workshop.courseTitle.toUpperCase() === courseTitleSearchSelected.toUpperCase()
            )
          });
          setWorkshopsToDisplay(workshops);
        }
      }
    }
  }, [courseTitleSearchSelected]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (userUsesAutocompleteSearch) {
      if (courseTitleInputValue === "") {
        setWorkshopsToDisplay(originalWorkshops);
        setCourseTitleSearchSelected("");
      }
    }
  }, [courseTitleInputValue]) // eslint-disable-line react-hooks/exhaustive-deps

  const handleCourseTitleInput = (e) => {
    setFilterMonthSelected("");
    setHighlightedMonthIndex(-1);
    setUserUsesMonthFilter(false);

    if (!userUsesAutocompleteSearch) {
      setUserUsesAutocompleteSearch(true);
    }

    if (courseTitleListFiltered.length !== 0) {
      setOpenCourseTitleList(true);
    } else {
      setOpenCourseTitleList(false);
    }

    setCourseTitleInputValue(e.target.value);
    setCourseTitleListFiltered(
      generateCourseTitleList(originalWorkshops).filter((element) => {
        return element.toUpperCase().startsWith(e.target.value.toUpperCase());
      })
    );
    if (e.key === "Enter" && courseTitleListFiltered.length === 1) {
      e.preventDefault();
      setCourseTitleSearchSelected(courseTitleInputValue);
      setOpenCourseTitleList(false);
    }
  };

  const handleCourseTitleMouseChange = (option) => {
    setFilterMonthSelected("");
    setHighlightedMonthIndex(-1);
    setUserUsesMonthFilter(false);

    if(!userUsesAutocompleteSearch) {
      setUserUsesAutocompleteSearch(true);
    };

    setCourseTitleSearchSelected(option);
    setCourseTitleInputValue(option);
    setOpenCourseTitleList(false);
    setHighlightedCourseTitleIndex(courseTitleListFiltered.indexOf(option));
  };

  const handleCourseTitleKeyDownChange = (e) => {
    setFilterMonthSelected("");
    setHighlightedMonthIndex(-1);
    setUserUsesMonthFilter(false);

    if (!userUsesAutocompleteSearch) {
      setUserUsesAutocompleteSearch(true);
    }
    if(!openCourseTitleList && e.key === "Enter") {
      e.preventDefault();
      setOpenCourseTitleList(true);
    }
    if(!openCourseTitleList) {
      setOpenCourseTitleList(true);
    }

    const courseTitles = courseTitleListFiltered;
    if (!openCourseTitleList || courseTitles.length === 0) return;

    if (e.key === 'ArrowDown') {
      e.preventDefault();
      setHighlightedCourseTitleIndex((prevIndex) => {
        const nextIndex = (prevIndex + 1) % courseTitles.length;
        return nextIndex;
      });
    } else if (e.key === 'ArrowUp') {
      e.preventDefault();
      setHighlightedCourseTitleIndex((prevIndex) => {
        const nextIndex = (prevIndex - 1 + courseTitles.length) % courseTitles.length;
        return nextIndex;
      });
    } else if (['Enter'].includes(e.key) && courseTitleListFiltered.length === 1) {
      e.preventDefault();
      setCourseTitleSearchSelected(courseTitleInputValue);
      setOpenCourseTitleList(false);
    } else if (['Enter', ' '].includes(e.key) && highlightedCourseTitleIndex >= 0) {
      e.preventDefault();
      handleCourseTitleMouseChange(courseTitles[highlightedCourseTitleIndex]);
    }
  };

  

  return matches ? (
    <>
      <Typography
        component="h2"
        sx={{
          fontWeight: 700,
          fontSize: "32px",
        }}
      >{`Upcoming workshops`}</Typography>

      {noWorkshops ? (
        <Grid item sx={{ padding: "50px 20px 100px 0" }}>
          <NoWorkshopsCard setValue={setValue} />
        </Grid>
      ) : (
        <Grid item sx={{ padding: "30px 20px 100px 0" }}>
          <Grid 
            item 
            sm={9}
            md={10}
            lg={12}
            xl={12}
          >
            <Box className={classes.mainFilterContainer}>
              <Box sx={{ marginBottom: "20px" }}>
                <Typography 
                  sx={{
                    fontStyle: "Roboto", 
                    fontWeight: "400px",
                    fontSize: "16px",
                    lineHeight: "18px",
                  }}
                >
                  {`Filter results:`}
                </Typography>
              </Box>
              <Box className={classes.actionFiltersContainer}>
                <form class="combobox-select" style={{ marginRight: "16px" }}>
                  <label
                    id="month-select-label"
                    htmlFor="month-select-button"
                    className={classes.filterLabels}
                  >
                    Select Month to View (optional)
                  </label>
                  <div className="enable-combobox__inner-container">
                    <div className="enable-combobox__controls-container">
                      <button
                        type="select"
                        id="month-select-button"
                        role="combobox"
                        aria-label="month"
                        aria-describedby="month-select-desc"
                        aria-controls="month-select-list"
                        aria-owns="month-select-list"
                        aria-expanded={openMonthSelectList}
                        value={filterMonthSelected}
                        onBlur={() => {
                          setOpenMonthSelectList(false);
                        }}
                        onClick={(event) => {
                          event.preventDefault();
                          setOpenMonthSelectList(!openCourseTitleList);
                        }}
                        onKeyDown={handleFilterMonthKeyDownChange}
                        style={{
                          display: "flex",
                          justifyContent: filterMonthSelected !== "" ? "space-between" : "flex-end",
                          alignItems: "center",
                          position: "relative",
                          boxSizing: "border-box",
                          minWidth: "200px",
                          height: "56px",
                          border: "1px solid #5E7079",
                          borderRadius: "4px",
                          backgroundColor: "#ffffff",
                          padding: "0 15px",
                          fontSize: "16px",
                          color: "#000000",
                          cursor: "pointer",
                        }}
                      > 
                        {filterMonthSelected}
                        <i >
                          {openMonthSelectList ?
                            <KeyboardArrowDownIcon 
                              sx={{
                                transform: "rotate(180deg)"
                              }}
                            />
                            :
                            <KeyboardArrowDownIcon 
                              sx={{
                                transform: "rotate(0deg)"
                              }}
                            />
                          }
                        </i>
                      </button>
                      {/* <div role="alert" aria-atomic="true" aria-live="polite" hidden="true" /> */}
                      <div role="alert" aria-atomic="true" aria-live="polite" id="selection-month-feedback" hidden="true">
                        {filterMonthSelected && `Selected month: ${filterMonthSelected}`}
                      </div>
                      <ul
                        ref={monthListRef}
                        role="listbox"
                        id="month-select-list"
                        tabIndex="-1"
                        hidden={!openMonthSelectList}
                        className={classes.monthSelectFilterListBox}
                      >
                        {generateMonthlyFilterOptions().map((option, index) => (
                          <MenuItem
                            id={`month-option-${index}`}
                            key={option}
                            role="option"
                            aria-selected={highlightedMonthIndex === index}
                            onMouseDown={() => handleFilterMonthMouseChange(option)}
                            onKeyDown={(event) => handleFilterMonthKeyDownChange(event, option)}
                            style={{
                              display: "flex",
                              alignItems: "center",
                              minHeight: "36px",
                              padding: "0px 16px",
                              fontSize: "16px",
                              fontFamily: "Roboto",
                              cursor: "pointer",
                              backgroundColor: highlightedMonthIndex === index ? 'rgba(220, 55, 39, 0.08)' : '#fff',
                              '& .hover': {
                                backgroundColor: 'rgba(220, 55, 39, 0.08)',
                              }
                            }}
                          >
                            {option}
                          </MenuItem>
                        ))}
                      </ul>
                      <div className="sr-only" id="month-select-desc" hidden="true">
                        Use the up and down arrow keys navigate month list.
                        You can press either spacebar or enter to select.
                      </div>
                    </div>
                  </div>
                </form>

                <form className="combobox-autocomplete">
                  <label
                    id="course-title-autocomplete-label"
                    htmlFor="course-title-autocomplete-input"
                    className={classes.filterLabels}
                  >
                    Type and select specific course title (optional)
                  </label>
                  <div className="enable-combobox__inner-container">
                    <div className="enable-combobox__controls-container">
                      <input
                        type="text"
                        role="combobox"
                        id="course-title-autocomplete-input"
                        aria-describedby="course-title-autocomplete-desc"
                        aria-controls="course-title-autocomplete-list"
                        aria-autocomplete="both"
                        aria-owns="course-title-autocomplete-list"
                        aria-expanded={openCourseTitleList}
                        autoCorrect="off"
                        autoCapitalize="off"
                        value={courseTitleInputValue}
                        onInput={handleCourseTitleInput}
                        onBlur={() => {
                          setOpenCourseTitleList(false);
                          setHighlightedCourseTitleIndex(-1);
                        }}
                        onKeyDown={handleCourseTitleKeyDownChange}
                        className={classes.courseTitleInputContainer}
                      />
                      {/* <div role="alert" aria-atomic="true" aria-live="polite" hidden="true" /> */}
                      <div role="alert" aria-atomic="true" aria-live="polite" id="selection-course-title-feedback" hidden="true">
                        {courseTitleSearchSelected && `Selected course title: ${courseTitleSearchSelected}`}
                      </div>
                      <ul
                        ref={courseTitleListRef}
                        role="listbox"
                        id="course-title-autocomplete-list"
                        tabIndex="-1"
                        hidden={!openCourseTitleList}
                        className={classes.courseTitleFilterListBox}
                      >
                        {courseTitleListFiltered.length > 0 && courseTitleListFiltered.map((option, index) => (
                          <MenuItem
                            id={`title-option-${option}`}
                            key={option}
                            role="option"
                            aria-selected={highlightedCourseTitleIndex === index}
                            onMouseDown={() => handleCourseTitleMouseChange(option)}
                            onKeyDown={(event) => handleCourseTitleKeyDownChange(event, option)}
                            style={{
                              display: "flex",
                              alignItems: "center",
                              minHeight: "36px",
                              padding: "0px 16px",
                              fontSize: "16px",
                              fontFamily: "Roboto",
                              cursor: "pointer",
                              backgroundColor: highlightedCourseTitleIndex === index ? 'rgba(220, 55, 39, 0.08)' : '#fff',
                              '& .hover': {
                                backgroundColor: 'rgba(220, 55, 39, 0.08)',
                              },
                            }}
                          >
                            {option}
                          </MenuItem>
                        ))}
                      </ul>
                      <div className="sr-only" id="course-title-autocomplete-desc" hidden="true">
                        As you type, press the enter key or use the up and down arrow keys to choose the autocomplete items.
                        You can press either spacebar or enter to select.
                      </div>
                    </div>
                  </div>
                </form>
              </Box>
            </Box>
          </Grid>
          <Box id="workshop_list">
            {workshopsToDisplay.length > 0 &&
              workshopsToDisplay.map((workshop) => (
                <>
                  <CourseCard
                    type="upcomingWorkshops"
                    currentCourse={{
                      courseId: workshop.courseCode,
                      courseOfferingId: workshop.id,
                      courseTitle: workshop.courseTitle,
                      description: workshop.courseDescription,
                      endDate: workshop.endDate,
                      offeringType: "WS",
                      rosterId: workshop.rosterId,
                      schedulingRole: workshop.role,
                      groupId: workshop.groupId,
                      status: workshop.status,
                      startDate: workshop.startDate,
                      capacity: workshop.capacity,
                      noOfParticipants: workshop.noOfParticipants,
                      facultyName: `${props.demographics.firstName} ${props.demographics.lastName}`,
                      accommodatedStudentIrnList: [],
                    }}
                    workshopPageCard={true}
                    wsUser={props.wsUser}
                  />
                </>
              ))
            }
          </Box>
        </Grid>
      )}
    </>
  ) : (
    <Box sx={{ display: "flex", flexDirection: "column" }}>
      <Typography
        component="h2"
        sx={{
          fontWeight: 700,
          fontSize: "24px",
          margin: "52px 0px 30px 18px",
        }}
      >{`Upcoming workshops`}</Typography>

      {noWorkshops ? (
        <Grid item sx={{ padding: "50px 20px 100px 0" }}>
          <NoWorkshopsCard setValue={setValue} />
        </Grid>
      ) : (
        <>
          <Grid 
            item 
            sm={9}
            md={10}
            lg={12}
            xl={12}
          >
            <Box className={classes.mainFilterContainerMobile}>
              <Box sx={{ marginBottom: "25px" }}>
                <Typography 
                  sx={{
                    fontStyle: "Roboto", 
                    fontWeight: "400px",
                    fontSize: "16px",
                    lineHeight: "18px",
                  }}
                >
                  {`Filter results:`}
                </Typography>
              </Box>
              <Box 
                sx={{ 
                  display: "flex", 
                  flexDirection: "column",
                  justifyContent: "space-between",
                  marginBottom: "50px", 
                }}
              >
                <form class="combobox-select" style={{ marginBottom: "20px" }}>
                  <label
                    id="month-select-label"
                    htmlFor="month-select-button"
                    className={classes.filterLabels}
                  >
                    Select Month to View (optional)
                  </label>
                  <div className="enable-combobox__inner-container">
                    <div className="enable-combobox__controls-container">
                      <button
                        type="select"
                        id="month-select-button"
                        role="combobox"
                        aria-label="month"
                        aria-labelledby="month-select-label"
                        aria-describedby="month-select-desc"
                        aria-controls="month-select-list"
                        aria-owns="month-select-list"
                        aria-haspopup="listbox"
                        aria-expanded={openMonthSelectList}
                        value={filterMonthSelected}
                        onBlur={() => {
                          setOpenMonthSelectList(false);
                        }}
                        onClick={(event) => {
                          event.preventDefault();
                          setOpenMonthSelectList(!openCourseTitleList);
                        }}
                        onKeyDown={handleFilterMonthKeyDownChange}
                        style={{
                          display: "flex",
                          justifyContent: filterMonthSelected !== "" ? "space-between" : "flex-end",
                          alignItems: "center",
                          position: "relative",
                          boxSizing: "border-box",
                          width: "100%",
                          minWidth: "282px",
                          maxWidth: "327px",
                          height: "56px",
                          border: "1px solid #5E7079",
                          borderRadius: "4px",
                          backgroundColor: "#ffffff",
                          padding: "0 15px",
                          fontSize: "16px",
                          color: "#000000",
                          cursor: "pointer",
                          placeholder: "Month"
                        }}
                      > 
                        {filterMonthSelected}
                        <i >
                          {openMonthSelectList ?
                            <KeyboardArrowDownIcon 
                              sx={{
                                transform: "rotate(180deg)"
                              }}
                            />
                            :
                            <KeyboardArrowDownIcon 
                              sx={{
                                transform: "rotate(0deg)"
                              }}
                            />
                          }
                        </i>
                      </button>
                      {/* <div role="alert" aria-atomic="true" aria-live="polite" hidden="true" /> */}
                      <div role="alert" aria-atomic="true" aria-live="polite" id="selection-month-feedback" hidden="true">
                        {filterMonthSelected && `Selected month: ${filterMonthSelected}`}
                      </div>
                      <div
                        ref={monthListRef}
                        role="listbox"
                        id="month-select-list"
                        tabIndex="-1"
                        hidden={!openMonthSelectList}
                        className={classes.monthSelectFilterListBoxMobile}
                      >
                        {generateMonthlyFilterOptions().map((option, index) => (
                          <MenuItem
                            id={`month-option-${index}`}
                            key={option}
                            role="option"
                            aria-label={option}
                            aria-selected={highlightedMonthIndex === index}
                            onMouseDown={() => handleFilterMonthMouseChange(option)}
                            onKeyDown={(event) => handleFilterMonthKeyDownChange(event, option)}
                            style={{
                              display: "flex",
                              alignItems: "center",
                              minHeight: "36px",
                              padding: "0px 16px",
                              fontSize: "16px",
                              fontFamily: "Roboto",
                              cursor: "pointer",
                              backgroundColor: highlightedMonthIndex === index ? 'rgba(220, 55, 39, 0.08)' : '#fff',
                              '& .hover': {
                                backgroundColor: 'rgba(220, 55, 39, 0.08)',
                              }
                            }}
                          >
                            {option}
                          </MenuItem>
                        ))}
                      </div>
                      <div className="sr-only" id="month-select-desc" hidden="true">
                        User can either drag or swipe finger to navigate month listbox.
                        Use double tap to select.
                      </div>
                    </div>
                  </div>
                </form>

                <form className="combobox-autocomplete">
                  <label
                    id="course-title-autocomplete-label"
                    htmlFor="course-title-autocomplete-input"
                    className={classes.filterLabels}
                  >
                    Type and select specific course title (optional)
                  </label>
                  <div className="enable-combobox__inner-container">
                    <div className="enable-combobox__controls-container">
                      <input
                        type="text"
                        role="combobox"
                        id="course-title-autocomplete-input"
                        aria-describedby="course-title-autocomplete-desc"
                        aria-controls="course-title-autocomplete-list"
                        aria-autocomplete="both"
                        aria-owns="course-title-autocomplete-list"
                        aria-expanded={openCourseTitleList}
                        aria-haspopup="listbox"
                        autoCorrect="off"
                        autoCapitalize="off"
                        value={courseTitleInputValue}
                        onInput={handleCourseTitleInput}
                        onBlur={() => {
                          setOpenCourseTitleList(false);
                          setHighlightedCourseTitleIndex(-1);
                        }}
                        onKeyDown={handleCourseTitleKeyDownChange}
                        className={classes.courseTitleInputContainerMobile}
                      />
                      {/* <div role="alert" aria-atomic="true" aria-live="polite" hidden="true" /> */}
                      <div role="alert" aria-atomic="true" aria-live="polite" id="selection-course-title-feedback" hidden="true">
                        {courseTitleSearchSelected && `Selected Course Title: ${courseTitleSearchSelected}`}
                      </div>
                      <ul
                        ref={courseTitleListRef}
                        role="listbox"
                        id="course-title-autocomplete-list"
                        tabIndex="-1"
                        hidden={!openCourseTitleList}
                        className={classes.courseTitleFilterListBoxMobile}
                      >
                        {courseTitleListFiltered.length > 0 && courseTitleListFiltered.map((option, index) => (
                          <MenuItem
                            id={`title-option-${option}`}
                            key={option}
                            role="option"
                            aria-label={option}
                            aria-selected={highlightedCourseTitleIndex === index}
                            onMouseDown={() => handleCourseTitleMouseChange(option)}
                            onKeyDown={(event) => handleCourseTitleKeyDownChange(event, option)}
                            style={{
                              display: "flex",
                              alignItems: "center",
                              minHeight: "36px",
                              padding: "0px 16px",
                              fontSize: "16px",
                              fontFamily: "Roboto",
                              cursor: "pointer",
                              backgroundColor: highlightedCourseTitleIndex === index ? 'rgba(220, 55, 39, 0.08)' : '#fff',
                         '& .hover': {
                                backgroundColor: 'rgba(220, 55, 39, 0.08)',
                              },
                            }}
                          >
                            {option}
                          </MenuItem>
                        ))}
                      </ul>
                      <div className="sr-only" id="course-title-autocomplete-desc" hidden="true">
                        As you type, use drag or swipe finger to navigate course title listbox.
                        Use double tap to select.
                      </div>
                    </div>
                  </div>
                </form>
              </Box>
            </Box>
          </Grid>
          <Box id="workshop_list" sx={{ padding: "10px 0 100px 0", alignSelf: "center" }}>
            {workshopsToDisplay.length > 0 &&
              workshopsToDisplay.map((workshop) => (
                <>
                  <CourseCard
                    type="upcomingWorkshops"
                    currentCourse={{
                      courseId: workshop.courseCode,
                      courseOfferingId: workshop.id,
                      courseTitle: workshop.courseTitle,
                      description: workshop.courseDescription,
                      endDate: workshop.endDate,
                      offeringType: "WS",
                      rosterId: workshop.rosterId,
                      schedulingRole: workshop.role,
                      groupId: workshop.groupId,
                      status: workshop.status,
                      startDate: workshop.startDate,
                      capacity: workshop.capacity,
                      noOfParticipants: workshop.noOfParticipants,
                      facultyName: "",
                      accommodatedStudentIrnList: [],
                    }}
                    workshopPageCard={true}
                    wsUser={props.wsUser}
                  />
                </>
              ))}
            </Box>
        </>
      )}
    </Box>
  );
};

const mapStateToProps = (state) => {
  return {
    demographics: state.demographics,
    workshops: state.workshops,
    sectionsForIrn: state.sectionsForIrn,
  };
};

export default connect(mapStateToProps)(UpcomingWorkshops);
